// Content
$si-content-bg:         	#fff !default;					// Background color of the infowindow
$si-content-padding:    	30px !default;					// Padding of the infowindow
$si-font-size:				14px !default;					// Font size of the infowindow content

// Border
$si-border-width: 			1px !default;					// Width of the border
$si-border-color: 			#bbb !default;					// Color of the border
$si-border-radius:      	3px !default;					// Border radius of the infowindow

// Pointer
$si-pointer-length:     	15px !default;					// Length of the pointer, from the edge of the infowindow to the tip

// Shadow
$si-shadow-h:           	0 !default;						// Shadow horizontal offset
$si-shadow-v:           	1px !default;					// Shadow vertical offset
$si-shadow-blur:        	3px !default;					// Shadow blur radius
$si-shadow-spread:      	0 !default;						// Shadow spread radius
$si-shadow-color:       	#000 !default;					// Shadow color
$si-shadow-opacity:     	0.298039 !default;				// Shadow opacity

// Close button
$si-close-font-size:		24px !default;					// Size of the close button text
$si-close-opacity:			0.5 !default;					// Initial opacity of the close button
$si-close-hover-opacity:	0.7 !default;					// Hover and focus opacity of the close button
