@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,600,700&subset=latin-ext');

$white: #fff;
$black: #000;


$grey-dark: #2f2f2f;
$grey-dark2: #252524;
$grey-body: #f2f2f2;
$grey1: #323232;
$grey2: #3f3f3f;
$grey-light1: #a4a4a0;
$grey-light2: #eeeeee;
$grey-light3: #f5f5f5;
$grey-p: #666666;
$grey-footer: #373737;

$yellow1: #fdb825;

$green-light: #0fd700;

$red1: #c12328;
$xs: 480px;
$sm: $screen-sm-min;
$md: $screen-md-min;
$lg: $screen-lg-min;

@mixin transform($x,$y) {
  -ms-transform: translate($x,$y);
  -webkit-transform: translate($x,$y);
  transform: translate($x,$y);
}

@mixin item-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  @include transform(-50%,-50%);
}

@mixin item-center-left($left: 0) {
  position: absolute;
  top: 50%;
  left: $left;
  @include transform(0,-50%);
}
@mixin item-center-right($right: 0) {
  position: absolute;
  top: 50%;
  right: $right;
  @include transform(0,-50%);
}
@mixin whole-item() {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
} 
@mixin transition($sec: .25s) {
  -webkit-transition: $sec;
  transition: $sec;
}
* {
  outline: none !important;
}
body {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  line-height: 1.27777778em;
  color: $grey-dark;
  position: relative;
}
h1, h2 ,h3 ,h4 ,h5 ,h6 {
  text-transform: uppercase;
  &.under-line {
    display: inline-block;
    position: relative;
    padding-bottom: 15px;
    line-height: 1;
    font-size: 32px;
    font-weight: 700;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 10px;
      right: 10px;
      background: $grey-dark;
      height: 2px;
    }
    &.white {
      color: $white;
      &:after {
        background: $white;
      }
    }
    &.yellow {
      &:after {
        background: $yellow1;
      }
    }
  }
}

a {
  @include transition();
}
a, a:hover, a:focus {
  text-decoration: none;
}
.container-big {
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  @include clearfix();
}
.btn {
  border: 1px solid $yellow1;
  color: $yellow1;
  background: transparent;
  font-size: 18px;
  padding: 14px 0;
  width: 255px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 0;
  @include transition();
  @media (min-width: $sm) {
    font-size: 22px;
  }
  &:hover, &:focus {
    color: $grey-dark;
    background: $yellow1;
  }
  &.white {
    color: $white;
    border-color: $white;
    &:hover, &:focus {
      color: $grey-dark;
      background: $white;
    }
  }
}
.btn-icon {
  margin: 20px 0;
  color: $black;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0;
  border: 0;
  height: 50px;
  background-color: transparent;
  span {
    vertical-align: middle;
    &.fa {
      background: transparent;
      width: 50px;
      height: 50px;
      border: 2px solid $grey2;
      position: relative;
      @include transition();
      &:before {
        @include item-center;
      }
      + span {
        margin-left: 12px;
      }
    }
    + .fa {
      margin-left: 12px;
    }
  }
  &:hover {
    .fa {
      background:$grey2;
      color: $white;
    }
  }
}
/*@import "bower_components/aos/dist/aos.css";*/
@import "bower_components/aos/src/sass/aos.scss";
@import "vendor/bower_components/snazzy-info-window/dist/snazzy-info-window.scss";
@import "vendor/bower_components/venobox/venobox/venobox.scss";
@import "header.scss";
@import "footer.scss";
@import "elements.scss";


