.main {
    overflow: hidden;
    .baner {
        background: url('../img/baner-mobile-bgd.jpg');
        padding-top: 85px;
        @media (min-width: $sm) {
            background: url('../img/top-main.jpg') center center no-repeat;
            background-size: cover;
        }
        @media (min-width: $lg) {
            height: 537px;
            padding-top: 140px;
        }
        /*    .container {
              height: 100%;
              .row {
                height: 100%;
                .baner-boxes {
                  height: 100%;
                }
              }
            }*/
        .baner-box {
            h1 {
                margin: 0;
                font-size: 35px;
                font-weight: 300;
                color: $white;
                text-transform: none;
                @media (min-width: $lg) {
                    margin: 0 60px;
                    font-size: 45px;
                }
                span {
                    text-transform: uppercase;
                    font-weight: 700;
                    display: block;
                    margin-top: 30px;
                }
            }
        }
        .baner-cta {
            margin-top: 35px;
            text-align: center;
            @media (min-width: $lg) {
                margin-top: 0;
            }
            .box-tel {
                padding: 11px;
                text-align: left;
                margin: 40px auto 0;
                background: rgba(#0d0b07, .4);
                color: $white;
                font-size: 20px;
                text-transform: uppercase;
                font-weight: 700;
                max-width: 290px;
                @media (min-width: $sm) {
                    font-size: 16px;
                }
                @media (min-width: $md) {
                    font-size: 20px;
                }
                > span:first-child {
                    display: block;
                    font-weight: 400;
                    margin-bottom: 10px;
                }
                a {
                    color: $white;
                    span {
                        color: $yellow1;
                        margin-right: 5px;
                    }
                    &:hover {
                        color: $yellow1;
                    }
                }
            }
        }
        .baner-img {
            pointer-events: none;
            position: relative;
            margin: 20px 0;
            @media (min-width: $lg) {
                margin: 70px 0 0;
            }
            img {
                max-width: 100%;
                @media (min-width: $lg) {
                    margin-top: -110px;
                }
            }
        }
    }
    .boxes-icons {
        padding: 40px 0;
        .box {
            .box-icon {
                position: relative;
                height: 120px;
                @media (min-width: $xs) {
                    height: 150px;
                }
                @media (min-width: $sm) {
                    height: 200px;
                }
                img {
                    max-width: 20%;
                    @include item-center();
                    @media (min-width: $sm) {
                        max-width: 100%;
                    }
                }
            }
            .box-text {
                text-align: center;
                h5 {
                    font-size: 22px;
                    font-weight: 600;
                    margin: 0 0 10px;
                    @media (min-width: $sm) {
                        margin: 0 0 30px;
                    }
                }
                p {
                    font-weight: 300;
                }
            }
        }
    }
}
.pillars {

    margin: 50px 0;
    @media (min-width: $sm) {
        background: url('../img/pillars-bgd.png') center center no-repeat;
        background-size: 100% 100%;
    }

    .asterix
    {
        font-size:10px;
        float: right;

    }


    .pillar-box {
        height: 360px;
        margin: 50px 0;
        position: relative;
        .pillar-main {
            width: 220px;
            position: absolute;
            bottom:0;
            left: 50%;
            @include transform(-50%,0);
            .pillar-txt {
                margin-bottom: 35px;
                .first, .number, .first_but_second {
                    display: block;
                    text-align: center;
                    font-weight: 700;
                    text-transform: uppercase;
                }
                .first {
                    font-size: 14px;
                    margin-bottom: 12px;

                }
                .first_but_second {
                    font-size: 24px;
                    margin-top: 30px;
                    display: block;
                    min-height: 32px;

                }
                .number {
                    font-size: 62px;
                }
            }
            .pillar {
                position: relative;
                background: url('../img/pillar-bgd.png');
                background-repeat: repeat-y;
                display: block;
                width: 53px;
                margin: 0 auto;
                /*height: 0;*/
                max-height: 334px;
                @include transition(1s);
                .pillar-top, .pillar-bottom {
                    position: absolute;
                    top: -13px;
                    left: 0;
                    display: block;
                    z-index: 1;
                    width: 53px;
                    height: 27px;
                    background: url('../img/pillar-up.png') center center no-repeat;
                }
                // .pillar-top {
                //     position: absolute;
                //     top: -53px;
                //     left:0px;
                //     display: block;
                //     z-index: 1;
                //     width: 63px;
                //     height: 67px;
                //     background: url('../img/pillar-up.png') center center no-repeat;
                // }
                .pillar-bottom {
                    top: initial;
                    bottom: -20px;
                    z-index: 0;
                    background: url('../img/pillar-bottom.png') center center no-repeat;
                    &:after {
                        content: '';
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        @include item-center();
                    }
                }
                .pillar-shadow {
                    position: absolute;
                    bottom: 0px;
                    left: 0;
                    height: 0px;
                    width: 53px;
                    display: block;
                    z-index: -1;
                    box-shadow: 0 7px 20px 4px;
                }
            }
        }
    }
}

.offert {
    margin: 60px 0;
    .offert-box {
        .offert-img-box {
            position: relative;
            height: 314px;
            z-index: 1;
            @media (min-width: $sm) {
                height: 207px;
            }
            @media (min-width: $lg) {
                height: 314px;
            }
            .offert-img {
                @include item-center();
                max-width: 100%;
                @media (min-width: $sm) {

                }
            }
        }
        .offert-title-box {
            img {
                margin: 30px 0;
            }
            span {
                display: block;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 30px;
                line-height: 30px;
                margin-bottom: 30px;
            }
            .btn {
                width: 210px;
                color: $grey-footer;
                border: 2px solid $grey-footer;
                &:hover {
                    background: $grey-footer;
                    color: $white;
                }
            }
        }
        &.center {
            @media (min-width: $lg) {
                margin-top: 185px;
            }
            .offert-img-box {
                z-index: 0;
            }
        }
        &.title {
            margin-bottom: 20px;
            @media (min-width: $sm) {
                margin-bottom: 0;
            }
            @media (min-width: $lg) {
                margin-bottom: -120px;
            }
            .offert-img-box {
                height: 220px;
                text-align: center;
                h4 {
                    display: inline-block;
                    position: relative;
                    padding-bottom: 15px;
                    margin-bottom: 0;
                    line-height: 1;
                    font-size: 32px;
                    font-weight: 700;
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 10px;
                        right: 10px;
                        background: #2f2f2f;
                        height: 2px;
                    }
                }
                img {
                    display: block;
                    margin: 0 auto;
                }
            }
            .offert-title-box {
                .btn {
                    position: relative;
                    z-index: 5;
                }
            }
        }
    }
}

.slider-text {
    .realizations-txt {
        font-weight: 300;
        color: $white;
    }
    .slick-dots {
        padding: 0;
        margin: 25px 0 0;
        text-align: center;
        li {
            display: inline-block;
            button {
                font-size: 0;
                line-height: 0;
                display: block;
                width: 20px;
                height: 20px;
                padding: 5px;
                cursor: pointer;
                color: transparent;
                border: 0;
                outline: none;
                background: transparent;
                position: relative;
                &:before {
                    @include item-center();
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    content: '';
                    display: block;
                    background: $yellow1;
                    @include transition();
                }
            }
            &:hover {
                button {
                    &:before {
                        width: 15px;
                        height: 15px;
                    }
                }
            }
            &.slick-active {
                button {
                    &:before {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            + li {
                margin-left: 6px;
            }
        }
    }
}
.slider-text-cat {



    .slider-cat-txt {
        font-weight: 300;
        line-height: 24px;
        font-size: 16px;
        color:$grey-p;
         height:240px;

    }
    .slider-cat-txt-main {
        font-weight: 400;
        line-height: 32px;
        font-size: 20px;
        color:$black;


    }
    .slick-dots {
        padding: 0;
        bottom: 20px;
        position: relative;
        margin: 35px 0 0;
        text-align: center;
        li {
            display: inline-block;
            button {
                font-size: 0;
                z-index: 100;
                line-height: 0;
                display: block;
                width: 20px;
                height: 20px;
                padding: 5px;
                padding-right: 25px;
                cursor: pointer;
                color: transparent;
                border: 0;
                outline: none;
                background: transparent;
                position: relative;
                &:before {
                    @include item-center();
                    width: 10px;
                    height: 10px;
                    border-radius: 90%;
                    content: '';
                    display: block;
                    background: $yellow1;
                    @include transition();
                }
            }
            &:hover {
                button {
                    &:before {
                        width: 15px;
                        height: 15px;
                    }
                }
            }
            &.slick-active {
                button {
                    &:before {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            + li {
                margin-left: 6px;
            }
        }
    }
}

.realizations {
    z-index: 25;
    position: relative;
    padding: 35px 0;
    margin: 0px 0;
    .realizations-txt {
        margin-top: 25px;
        font-weight: 300;
        color: $white;
        @media (min-width: $md) {
            text-align: center;
        }
    }
    .realizations-bgd {


        @include item-center();
        /*position: absolute;*/
        width: 120%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before {
            width: 100%;
            height:100%;
            content: "";
            background: url('../img/real-bgd.jpg');
            transform: rotate(-3deg);
        }
    }
}

#realizations {
    overflow: hidden;
    position: relative;
    padding: 40px 0;
    &:before, &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 30%;
        width: 50px;
        /*background: $black;*/
        /*background: #252524;*/

        z-index: 1;
        @media (min-width: $lg) {
            bottom: 0;
            background: url('../img/real-bgd.jpg');
        }
    }
    &:before {
        left: 0;
    }
    &:after {
        right: 0;
    }
    .item-slider {
        .img-box {
            /*padding: 0 30px;*/
            height: 180px;
            margin: 0 30px;
            @media (min-width: $md) {
                height: 255px;
            }
            @media (min-width: $lg) {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            img {
                max-width: 100%;
                max-height: 100%;
                margin: 0 auto;
                @include transition();
                transform: scale(1);
                box-shadow: 0 5px 20px rgba($black,.5);

                &:hover
                    {

                }

                // box-shadow: inset 0px 0px 79px 20px rgba(0,0,0,0.9)!important;
                // border:1px solid #ff6600;

                @media (min-width: $lg) {
                    max-height: auto;
                }
            }
        }
        .txt-box {
            margin-top: 20px;
            opacity: 1;
            @media (min-width: $lg) {
                margin-top: 40px;
            }
            @include transition();
            @media (min-width: $lg) {
                opacity: 0;
            }
            span {
                display: block;
                color: $white;
                text-align: center;
                &.top {
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 25px;
                }
                &.bottom {
                    margin-top: 20px;
                }
            }
        }
        &.slick-center {
            .img-box {
                img {
                    transform: scale(1.2);
                }
            }
            .txt-box {
                opacity: 1;
            }
        }
    }
    .left, .right {
        /*    position: absolute;
            top: 50%;*/
        width: 50px;
        height: 50px;
        border: 2px solid darken($yellow1,20%);
        z-index: 2;
        border-radius: 100%;
        cursor: pointer;
        @include transition();
        span {
            @include whole-item();
            @include transition();
            color: darken($yellow1,20%);
            &:before {
                @include item-center();
            }
            &:after {
                content: '';
                border-bottom: 2px dashed darken($yellow1,20%);
                width: 40px;
                display: block;
                @include transition();
            }
        }
        &:hover {
            border-color: $yellow1;
            span {
                color: $yellow1;
                &:after {
                    border-color: $yellow1;
                }
            }
        }
    }
    .left {
        @include item-center-left(0);
        span {
            &:before {
                margin-left: -1px;
                margin-top: 1px;
            }
            &:after {
                @include item-center-left(100%);
            }
        }
    }
    .right {
        @include item-center-right(0);
        span {
            &:before {
                margin-left: 1px;
                margin-top: 1px;
            }
            &:after {
                @include item-center-right(100%);
            }
        }
    }
}

.gallery {
    margin-top: 40px;
    h3 {
        margin-bottom: 40px;
    }
}
.gallery-subpage-box {
    position: relative;
    margin-bottom: 40px;
    z-index: 1;
    &:after {
        z-index: -1;
        content: '';
        height: 60%;
        width: 100%;
        background: $yellow1;
        @include item-center();
    }
}
#gallery-subpage {
    /*overflow: hidden;*/
    position: relative;
    /*padding: 40px 0;*/
    /*    .slick-list {
            overflow: visible;
        }*/
    .item-slider {
        .img-box {
            height: 180px;
            margin: 0 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (min-width: $md) {
                height: 255px;
            }
            img {
                max-width: 100%;
                max-height: 100%;
                margin: 0 auto;
                @include transition();
                transform: scale(1);
                box-shadow: 0 5px 10px rgba($black,.5);
                @media (min-width: $lg) {
                    max-height: auto;
                }
            }
        }
        .txt-box {
            margin-top: 20px;
            opacity: 1;
            @media (min-width: $lg) {
                margin-top: 40px;
            }
            @include transition();
            @media (min-width: $lg) {
                opacity: 0;
            }
            span {
                display: block;
                color: $white;
                text-align: center;
                &.top {
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 25px;
                }
                &.bottom {
                    margin-top: 20px;
                }
            }
        }
        &.slick-current {
            + .slick-active {
                .img-box {
                    img {
                        @media (min-width: $lg) {
                            transform: scale(1.2);
                        }
                    }
                }
            }
        }
    }
    .left, .right {
        width: 50px;
        height: 50px;
        border: 2px solid darken($yellow1,20%);
        z-index: 2;
        border-radius: 100%;
        cursor: pointer;
        @include transition();
        span {
            @include whole-item();
            @include transition();
            color: darken($yellow1,20%);
            &:before {
                @include item-center();
            }
            &:after {
                content: '';
                border-bottom: 2px dashed darken($yellow1,20%);
                width: 40px;
                display: block;
                @include transition();
            }
        }
        &:hover {
            border-color: $white;
            /*            @media (min-width: $lg) {
                            border-color: $yellow1;
                        }*/
            span {
                color: $white;
                /*                @media (min-width: $lg) {
                                    color: $yellow1;
                                }*/
                &:after {
                    border-color: $white;
                    /*                    @media (min-width: $lg) {
                                            border-color: $yellow1;
                                        }*/
                }
            }
        }
    }
    .left {
        @include item-center-left(0);
        @media (min-width: $lg) {
            @include item-center-left(-30px);
        }
        span {
            &:before {
                margin-left: -1px;
                margin-top: 1px;
            }
            &:after {
                @include item-center-left(100%);
            }
        }
    }
    .right {
        @include item-center-right(0);
        @media (min-width: $lg) {
            @include item-center-right(-30px);
        }
        span {
            &:before {
                margin-left: 1px;
                margin-top: 1px;
            }
            &:after {
                @include item-center-right(100%);
            }
        }
    }
}



.gallery-youtube-box {
    position: relative;
    margin-bottom:100px;
    z-index: 1;
    &:after {
        z-index: -1;
        content: '';
        height: 60%;
        width: 100%;
        background: $yellow1;
        @include item-center();
    }
}
#gallery-youtube {
    /*overflow: hidden;*/
    position: relative;

    /*padding: 40px 0;*/
    /*    .slick-list {
            overflow: visible;
        }*/
    .item-slider {
        .img-box {
            height: 180px;
            margin: 0 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (min-width: $md) {
                height: 255px;
            }
            .vbox-item {
                position: relative;
                .play-btn {
                    @include item-center();
                    color: $yellow1;
                    border: 2px solid $black;
                    padding: 8px 13px;
                    overflow: hidden;
                    width: 44px;
                    height: 45px;
                    white-space: nowrap;
                    z-index: 2;
                    @include transition();
                    span {
                        vertical-align: middle;
                    }
                    .fa {
                        font-size: 24px;
                    }
                    .play-btn-txt {
                        opacity: 0;
                        width: 101px;
                        margin-left: 5px;
                        @include transition();
                    }
                    &:after {
                        content: '';
                        display: block;
                        background: $black;
                        top: 0;
                        position: absolute;
                        bottom: 0;
                        right: 100%;
                        left: 0;
                        z-index: -1;
                        @include transition();
                    }
                }
                &:hover {
                    .play-btn {
                        width: 155px;
                        .play-btn-txt {
                            opacity: 1;
                        }
                        &:after {
                            right: 0;
                        }
                    }
                }
            }
            img {
                max-width: 300px;
                height: 218px;
                border: 0px;
                margin: 0 auto;
                @include transition();
                transform: scale(1);
                box-shadow: 0 5px 10px rgba($black,.5);

                // filter:grayscale(80%);
                &:hover
                    {

                }
                @media (min-width: $lg) {
                    max-height: auto;
                }
            }
        }
        .txt-box {
            margin-top: 20px;
            opacity: 1;
            @media (min-width: $lg) {
                margin-top: 40px;
            }
            @include transition();
            @media (min-width: $lg) {
                opacity: 0;
            }
            span {
                display: block;
                color: $white;
                text-align: center;
                &.top {
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 25px;
                }
                &.bottom {
                    margin-top: 20px;
                }
            }
        }
        &.slick-current {
            + .slick-active {
                .img-box {
                    img {
                        @media (min-width: $lg) {
                            transform: scale(1.2);
                        }
                    }
                }
            }
        }
    }
    .left, .right {
        width: 50px;
        height: 50px;
        border: 2px solid darken($yellow1,20%);
        z-index: 2;
        border-radius: 100%;
        cursor: pointer;
        @include transition();
        span {
            @include whole-item();
            @include transition();
            color: darken($yellow1,20%);
            &:before {
                @include item-center();
            }
            &:after {
                content: '';
                border-bottom: 2px dashed darken($yellow1,20%);
                width: 40px;
                display: block;
                @include transition();
            }
        }
        &:hover {
            border-color: $white;
            /*            @media (min-width: $lg) {
                            border-color: $yellow1;
                        }*/
            span {
                color: $white;
                /*                @media (min-width: $lg) {
                                    color: $yellow1;
                                }*/
                &:after {
                    border-color: $white;
                    /*                    @media (min-width: $lg) {
                                            border-color: $yellow1;
                                        }*/
                }
            }
        }
    }
    .left {
        @include item-center-left(0);
        @media (min-width: $lg) {
            @include item-center-left(-30px);
        }
        span {
            &:before {
                margin-left: -1px;
                margin-top: 1px;
            }
            &:after {
                @include item-center-left(100%);
            }
        }
    }
    .right {
        @include item-center-right(0);
        @media (min-width: $lg) {
            @include item-center-right(-30px);
        }
        span {
            &:before {
                margin-left: 1px;
                margin-top: 1px;
            }
            &:after {
                @include item-center-right(100%);
            }
        }
    }
}

.contact-form {
    /*background: $grey-dark2;*/
    background: url('../img/contact-form-bgd.jpg') center center no-repeat;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
    h4 {
        margin: 0 auto;
    }
    form {
        margin: 60px auto;
        .form-group {
            margin-bottom: 0;
            font-size: 14px;
            position: relative;
            height: 64px;
            label {
                color: $white;
                position: absolute;
                top: 32px;
                padding: 0 10px;
                margin: 0;
                width: 100%;
                text-transform: uppercase;
                @include transition();
            }
            .form-control {
                position: absolute;
                bottom: 0;
                padding: 10px;
                min-height: 40px;
                height: auto;
                background-color: transparent;
                border: 0;
                border-bottom: 2px solid $grey-footer;
                border-radius: 0;
                box-shadow: none;
                resize: none;
                font-weight: 700;
            }
            &.active {
                label {
                    top: 0;
                }
            }
            + .form-group {
                margin-top: 15px;
            }
            &.textarea-box {
                height: 222px;
                margin-top: 20px;
                @media (min-width: $md) {
                    margin-top: 0;
                }
                textarea {
                    height: 199px;
                    &::-webkit-scrollbar {
                        width: 2px;
                    }
                    &::-webkit-scrollbar-track {
                        -webkit-box-shadow: inset 0 0 6px gray;
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: $yellow1;
                    }
                    &.form-control {
                        margin-top: 23px;
                    }
                }
            }
        }

        .btn {
            margin-top: 70px;
            /*padding: 14px 84px;*/
            border: 3px solid $grey-footer;
            color: $grey-footer;
            &:hover {
                background: $grey-footer;
                color: $white;
            }
        }
    }
}
/*---- sub-main ----*/
.sub-main {
    padding-top: 80px;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    h1, h3 {
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 25px;
    }
    h3 {
        font-size: 18px;
        color: $grey-light1;

    }
    h1 {
        font-size:42x;
        color: $grey-dark2;
    }
    .product-box {
        margin-top: 55px;

        .product-box-date {
            display: flex;
            align-items: center;
            justify-content: left;
            background: $grey-light3;
            padding: 5px 0;
            @media (min-width: $sm) {
                width: 100%;
                float: left;
            }
            .product-box-date-desc {
                padding:5px 0px 8px 0;
                h6 {
                    margin: 0 0 5px;
                    font-size: 14px;
                    font-weight: 700;
                    float: left;
                    display:inline-block;
                    text-transform: uppercase;
                    color: #252524;
                    width: 67%;

                }
                span {
                    font-size: 34px;
                    float: left;
                    color: $yellow1;
                    font-weight: 700;
                    margin-top: 5px;
                }
                img
                {
                    display: block;
                    float: left;
                    margin-right: 20px;

                }
            }
        }
        &.next-box {
            margin-top: 0;
        }
    }

    .box-tel {
        padding: 11px;
        text-align: left;
        margin: 0px auto 0;
        background: rgba(#0d0b07, .4);
        color: $white;
        float:right;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 700;
        max-width: 290px;
        @media (min-width: $sm) {
            font-size: 16px;
        }
        @media (min-width: $md) {
            font-size: 20px;
        }
        > span:first-child {
            display: block;
            font-weight: 400;
            margin-bottom: 10px;
        }
        a {
            color: $white;
            span {
                color: $yellow1;
                margin-right: 5px;
            }
            &:hover {
                color: $yellow1;
            }
        }
        &.product-detils {
            float:none;
            @media (min-width: $sm) {
                float:right;
            }
        }
    }
}
.sub-main-bgd {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 600px;
    background: url('../img/subpage-bgd-mobile.jpg') center center no-repeat;
    background-size: cover;
    @media (min-width: $sm) {
        background: url('../img/subpage-bgd.jpg') center center no-repeat;
        height: 530px;
    }
}
h3.dt {
    margin-bottom: 30px;
}
.crane-first-desc-box {
    p {
        line-height: 26px;
        font-size: 16px;
        margin: 0;
    }
}
.crane-img-box {
    min-height: 240px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    @media (min-width: $sm) {
        min-height: 400px;
    }
    img {
        max-width: 100%;
    }
}
.crane-columns-desc-box {
    margin-top: 46px;
    min-height: 264px;

    @media (min-width: $sm) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .crane-columns-desc-txt {

        font-size: 20px;
        font-weight: 300;
        line-height: 32px;

        @media (min-width: $sm) {
            column-count: 2;
            column-gap: 25px;
        }
    }
}
.availability-box-btn {
    text-align: left;
    margin: 15px 0;
    @media (min-width: $sm) {
        text-align: right;
        margin: 0;
    }
    .btn-green {
        display: inline-block;
        font-size: 16px;
        font-weight: 700;
        color: $green-light;
        background:  $white;
        text-transform: uppercase;
        border: 3px solid $green-light;
        padding: 21px;
        @media (min-width: $sm) {
            padding: 21px 42px;
            font-size: 18px;
        }
        @include transition();
        &:hover {
            color: $white;
            background: $green-light;
        }
    }
}
.other-product {
    margin: 30px 0;
}
.other-box {
    display: block;
    margin-bottom: 40px;
    .other-box-img {
        position: relative;
        height: 250px;
        overflow: hidden;
        img {
            max-width: 100%;
            @include item-center;
        }
        .other-box-hover-mask {
            @include whole-item;
            background: rgba($yellow1, .8);
            opacity: 0;
            @include transition(.3s);
            span {
                font-size: 22px;
                color: $white;
                @include item-center;
                &.fa {
                    font-size: 42px;
                    margin-top: -10px;
                }
                &.other-box-hover-mask-details {
                    margin-top: 35px;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
        }
    }
    .other-box-txt {
        font-size: 22px;
        font-weight: 700;
        color: $white;
        background: $grey-dark2;
        border: 2px solid $grey-dark;
        padding: 6px 18px;
    }
    &:hover {
        .other-box-img {
            .other-box-hover-mask {
                opacity: 1;
            }
        }
    }
}
/*---- select custom ----*/
.select-hidden {
    display: none;
    visibility: hidden;
    padding-right: 10px;
}
.select {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: 16px;
    color: $white;
    width: 100%;
    height: 43px;
    margin-top: 21px;
}
.select-styled {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid $grey-footer;
    padding: 10px;
    text-transform: uppercase;
    @include transition(all 0.2s ease-in);
    color: $white;
    font-weight: 700;
    &:after {
        content:"\f078";
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        position: absolute;
        @include item-center-right(10px);
        @include transition(all 0.2s ease-in);
        color: $white;
    }
    &:hover {
        background-color: $grey-footer;
        color: $white;
        &:after {
            color: $yellow1;
        }
    }
    &:active, &.active {
        background-color: darken($grey-footer, 5);
        color: $white;
        &:after {
            color: $yellow1;
        }
    }
}

.select-options {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 999;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: darken($grey-footer, 5);
    li {
        margin: 0;
        padding: 12px 0;
        text-indent: 15px;
        border-top: 1px solid $grey-footer;
        text-transform: uppercase;
        @include transition(all 0.15s ease-in);
        &:hover {
            color: $grey-footer;
            background: $white;
        }
        &[rel="hide"] {
            display: none;
        }
    }
}

/*---- slider ----*/
.slider-box {
    margin-top: 0px;
    #slider {
        margin: 90px 0;
        position: relative;
        .slick-arrow {
            z-index: 10;
            @include transition();
            color: $grey1;
            cursor: pointer;
            width: 50px;
            height: 100%;
            span {
                @include item-center;
            }
            &.left {
                @include item-center-left();
                left: 0;
            }
            &.right {
                right: 0;
                @include item-center-right();
            }
            &:hover {
                color: $yellow1;
            }
        }
        .slick-list {
            width: calc(100% - 100px);
            margin: 0 auto;
            .slick-track {
                .item {
                    text-align: center;
                    height: 50px;
                    line-height: 50px;
                    img {
                        display: inline;

                        height: 40px;
                    }
                }
            }
        }
    }
}

#map
{height: 600px;
 width: 100%;
 top:-45px;
 z-index: 10;
 position: relative;
 z-index: 10;
 margin-bottom: 15px;
 margin-top:0px;


 &:after{
     width: 140%;
     height:20%;
     left:-10%;
     content: "";
     background-color: #ffffff;
     transform: rotate(-3deg);
     position: absolute;
     bottom:-60px;
 }

}

.business-card2 {

    padding: 15px;

    width: 420px;
    @media (max-width: $sm) {
        width: 270px;
    }
    @include transition();
    background-color: $grey-dark;
    color: $white;
    display: block;

    &:before {
        position: absolute;
        top: -15px;
        height: 15px;
        width: 100%;
        content: '';
    }
    .bc-logo {
        width: 150px;

        @media (max-width: $sm) {
            display: none;
        }
    }
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            text-align: left;
            color: $white;
            padding-left: 30px;
            position: relative;
            font-size: 14px;
            font-weight: 300;
            a {
                color: $white;
                &:hover {
                    text-decoration: underline;
                }
            }
            span {
                position: absolute;
                top: 3px;
                left: 0;
                color: $yellow1;
            }
            + li {
                margin-top: 10px;
            }
        }
    }
}

.tooltip
{
    margin: 0px;


    .tooltip-arrow {


    }

    .tooltip-inner
    {
        max-width: 350px;
        font-size: 14px;
        width: 350px;
        padding: 15px;
        background-color: $yellow1;
        color:black;
        font-family: 'Raleway', sans-serif;
    }
    .tooltip.in
    {
        opacity: 1 !important;
        filter: alpha(opacity=100);
    }

}
 .offer_list
    {


        li
        {
            text-align: left;
            font-size:16px;
            line-height: 24px;
            padding: 0px;
            margin: 0px;
        }
    }
