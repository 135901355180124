/*@media (min-width: $sm) {}*/
header {
    background-color: rgba($grey-dark, .1);
    @include transition();
    @media (min-width: $sm) {
        /*background-color: transparent;*/
    }
    .navbar-default {
        background-color: transparent;
        border: 0;
        min-height: auto;
        margin-bottom: 5px;
        .navbar-header {
            .mobile-logo {
                display: inline-block;
                width: 150px;
                height: 63px;
                position: relative;
                img {
                    @include item-center();
                    @include transition();
                    &.logo-yellow {
                        opacity: 0;
                    }
                }
            }
            .business-card {
                visibility: hidden;
                opacity: 0;
                padding: 15px;
                position: absolute;
                width: 420px;
                @include transition();
                background-color: $grey-dark;
                color: $white;
                display: none;
                @media (min-width: $sm) {
                    display: block;
                }
                &:before {
                    position: absolute;
                    top: -15px;
                    height: 15px;
                    width: 100%;
                    content: '';
                }
                .bc-logo {
                    width: 120px;
                }
                ul {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    li {
                        text-align: left;
                        color: $white;
                        padding-left: 30px;
                        position: relative;
                        font-size: 14px;
                        font-weight: 300;
                        a {
                            color: $white;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                        span {
                            position: absolute;
                            top: 3px;
                            left: 0;
                            color: $yellow1;
                        }
                        + li {
                            margin-top: 10px;
                        }
                    }
                }
            }
            .navbar-toggle {
                margin-top: 17px;
                border-color: $yellow1;
                .icon-bar {
                    background-color: $yellow1;
                }
                &:hover,&:focus {
                    background-color: $yellow1;
                    .icon-bar {
                        background-color: $white;
                    }
                }
            }
            &:hover {
                .business-card {
                    margin-top: 15px;
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
        .navbar-collapse {
            border: none;
            box-shadow: none;
            &::-webkit-scrollbar {
                width: 2px;
            }
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px gray;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $yellow1;
            }
            .nav {
                padding:13px 0;
                @media (min-width: $sm) {
                    float: right;
                }
                li {
                    a {
                        text-transform: uppercase;
                        position: relative;
                        font-size: 18px;
                        font-weight: 700;
                    }
                }
                > li {

                    > a {

                        color: $white;
                        padding: 10px 25px;
                        &:after {
                            content: '';
                            background: $yellow1;
                            height: 4px;
                            position: absolute;
                            bottom: 0;
                            left: 20px;
                            right: 20px;
                            opacity: 0;
                            @include transition();
                        }
                        span {
                            position: relative;
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            font-size: 10px;
                            vertical-align: bottom;
                            &:before {
                                @include item-center();
                            }
                        }
                    }
                    .dropdown-menu {
                        background-color:$grey-dark;
                        padding: 0 20px;
                        border-radius: 0;
                        border: none;
                        box-shadow: none;
                        > li {
                            > a {
                                padding: 15px 10px;
                                color: $white;
                                border-bottom: 2px solid #676765;
                                font-size: 16px;
                                @media (min-width: $sm) {
                                    font-size: inherit;
                                }
                                &:hover, &:focus  {
                                    background-color: transparent;
                                    color: $yellow1;
                                }
                                span {
                                    position: relative;
                                    display: inline-block;
                                    width: 20px;
                                    height: 20px;
                                    font-size: 10px;
                                    vertical-align: bottom;
                                    &:before {
                                        @include item-center();
                                    }
                                }
                            }
                            &.dropdown-submenu {
                                > .dropdown-menu {
                                    margin-top: 1px;

                                    @media (min-width: $sm) {
                                        margin-left: 25px;
                                    }
                                    &:after {
                                        content: '';
                                        background: $yellow1;
                                        height: 2px;
                                        position: absolute;
                                        bottom: -5px;
                                        left:0;
                                        right: 0;
                                        opacity: 1;
                                        @media (min-width: $sm) {
                                            height: 10px;
                                            bottom: -15px;
                                        }
                                    }
                                }
                            }
                            &:last-child {
                                > a {
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                    > .dropdown-menu {
                        @media (min-width: $sm) {
                            left: 50%;
                            @include transform(-50%,0);
                            margin-top: 14px;
                            &:after {
                                content: '';
                                background: $yellow1;
                                height: 10px;
                                position: absolute;
                                bottom: -15px;
                                left:0;
                                right: 0;
                                opacity: 1;
                            }
                        }
                    }
                    &.open ,&:hover {
                        > a {
                            color: $yellow1;
                            background-color: transparent;
                            &:after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
    .dropdown-submenu{
        position:relative;
    }
    .dropdown-submenu>.dropdown-menu{
        top:-53px;
        left:100%;
        margin-top:-6px;
        margin-left:-1px;
    }
    .dropdown-submenu.pull-left{
        float:none;
    }
    .dropdown-submenu.pull-left>.dropdown-menu{
        left:-100%;
        margin-left:10px;
    }
    &:hover, &.affix {
        background-color:$grey-dark;
        .navbar-default {
            .navbar-header {
                .mobile-logo {
                    img {
                        opacity: 1;
                        &.logo-yellow {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}