/*@media (min-width: $sm) {}*/
footer {
  padding: 30px 0;
  background: url('../img/footer-bgd.jpg');
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .footer-contact {
    ul {
      margin-top: 25px;
      @media (min-width: $md) {
        margin-left: 31px;
      }
      @media (min-width: $lg) {
        margin-left: 61px;
      }
      li {
        text-align: left;
        color: $white;
        padding-left: 40px;
        position: relative;
        font-size: 16px;
        font-weight: 300;
        @media (min-width: $lg) {
          font-size: 18px;
        }
        a {
          color: $white;
          &:hover {
            text-decoration: underline;
          }
        }
        span {
          position: absolute;
          top: 3px;
          left: 0;
          color: $yellow1;
        }
        + li {
          margin-top: 10px;
          @media (min-width: $md) {
            margin-top: 20px;
          }
        }
      }
    }
  }
  .footer-menu {
    margin-top: 20px;
    a {
      color: $yellow1;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 15px;
      display: inline-block;
      @media (min-width: $sm) {
        margin-top: 0;
      }
      @media (min-width: $lg) {
        font-size: 18px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    ul {
      margin-top: 20px;
      li {
        a {
          color: $white;
          font-size: 14px;
          font-weight: 500;
          text-transform: none;
          margin-top: 0;
          @media (min-width: $lg) {
            font-size: 16px;
          }
        }
        + li {
          margin-top: 10px;
        }
      }
    }
  }
}